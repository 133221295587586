import React from 'react';
import './styles/App.css';
import './styles/bootstrap.css';
import LoginPage from './shared/ui-auth/LoginPage';
import RegisterPage from './shared/ui-auth/RegisterPage';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ForgotPasswordPage from './shared/ui-auth/ForgotPasswordPage';
import ResetPasswordPage from './shared/ui-auth/ResetPasswordPage';
import ProfilePage from './shared/ProfilePage';
import GraphQLAppHomePage from './apps/graphql-app/GraphQLAppHomePage';
import IDCard from './apps/certificate/IDCard';
import FounderProfilePage from './apps/indie-profile/FounderProfilePage';

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/graphql" element={<GraphQLAppHomePage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/register" element={<RegisterPage />} />
          <Route path="/forget_password" element={<ForgotPasswordPage />} />
          <Route path="/reset_password" element={<ResetPasswordPage />} />
          <Route path="/profile" element={<ProfilePage />} />
          <Route path="/idcard" element={<IDCard />} />
          <Route path="/" element={<FounderProfilePage />} />
          {/* Add other routes here */}
        </Routes>
      </div>
    </Router>
  );
}

export default App;
