import React from 'react';
import Carousel from './Carousel';


const avatars = [
    'https://img0.baidu.com/it/u=3992066725,2379971241&fm=253&fmt=auto&app=120&f=JPEG?w=500&h=501',
    'https://img0.baidu.com/it/u=2020394484,4180420341&fm=253&fmt=auto&app=120&f=JPEG?w=889&h=500',
    'https://img1.baidu.com/it/u=4176902140,1808982150&fm=253&fmt=auto&app=120&f=JPEG?w=889&h=500',
    'https://img1.baidu.com/it/u=3673033527,918106645&fm=253&fmt=auto&app=120&f=JPEG?w=500&h=750',
];

const FounderProfilePage: React.FC = () => {
    return (
        <div className="bg-amber-300 flex justify-center">
            <div className="p-2 bg-gray-100 flex-column max-w-screen-sm rounded">

                <div key="profile-header" className="mb-4">
                    <Carousel images={avatars}/>
                </div>

                <div key="profile-info" className="mb-4 p-4 bg-white rounded-lg flex-column text-left items-center">
                    <p>
                        <text className="font-bold">Akria</text>
                        <text className="mx-2">|</text>
                        <text>Canada</text>
                    </p>

                    <p className="mb-1">
                        <text>Cups: A</text>
                        <text className="mx-2">|</text>
                        <text>Body: Slim</text>
                        {/*<text className="mx-2">|</text>*/}
                        {/*<text>Height: 166cm</text>*/}
                        {/*<text className="mx-2">|</text>*/}
                        {/*<text>Weight: 48kg</text>*/}
                        <text className="mx-2">|</text>
                        <text>Age: Early 20s</text>
                    </p>
                </div>

                <div key="contact-info" className="mb-4 p-4 bg-white rounded-lg flex-row justify-start text-left">
                    <p>Text (SMS) +16043320887</p>
                    <p>Telegram: @akira</p>
                    <p>X: @akira</p>
                </div>

                <div id="location" key={1} className="mb-4 p-4 bg-white rounded-lg flex-column items-center text-left">
                    <p className="mt-2">
                        <text className="font-bold">Location:</text>
                        <text className="mx-2"></text>
                        <text>Metrotown Burnaby, Transport: Metrotown</text>
                    </p>
                    <p className="mt-2">
                        <text className="font-bold">Location:</text>
                        <text className="mx-2"></text>
                        <text>Metrotown Burnaby, Transport: Metrotown</text>
                    </p>
                </div>

                <div id="service-info" key={1} className="mb-4 p-4 bg-white rounded-lg flex items-center text-left">
                    <div className="flex-grow-1 flex-row">
                        <h6 className="font-semibold">Service:</h6>
                        <p className="mt-2">30min(260$); 45 min(280$) ; 1h(320$)</p>
                    </div>
                    <button id="calendar" className="text-center  ml-3 bg-cyan-400 rounded-3xl text-white p-2">Services
                    </button>
                </div>


                <div id="service-calendar" key={1} className="mb-4 p-4 bg-white rounded-lg flex items-center">
                    <div className="flex-grow-1 text-left">
                        <h6 className="font-semibold">Available:</h6>
                        <p className="text-left">Today </p>
                    </div>

                    <button id="calendar"
                            className="text-center  ml-3 bg-cyan-400 rounded-3xl text-white p-2">Calendar
                    </button>
                </div>

                <div key={'review'} className="mb-4 p-4 bg-white rounded-lg flex flex-column align-content-start ">
                    <div className="flex">
                        <h6 className="font-semibold flex-grow-1 text-left">Review</h6>
                        <p className="">2024.09.24</p>
                        <p className="ml-3">Rating: 5/5</p>
                    </div>
                    <p id={'review_content'} className="mt-2 text-left text-ellipsis line-clamp-2">
                        She's a very down to earth gal. Your girl next door.. I can't believe she's so pretty kinda
                        girl. Enjoyed
                        talking to her as much as doing the other stuff...
                    </p>
                    <a href="#" className="text-right text-blue-500">More Reviews</a>
                </div>

                <div key="safety" className="mb-4 p-4 bg-white rounded-lg flex">
                    <h3 className="text-xl font-semibold">Health and Safety Info</h3>
                </div>
            </div>
        </div>
    );
};

export default FounderProfilePage;
